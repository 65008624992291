<template>
  <div v-show="detail.name" class="detail elite-detail">
    <div class="title">
      <div v-if="type == 'club'" class="name">{{detail.detail_title}}</div>
      <div v-else class="name">{{detail.name}}</div>
      <div class="info">发布时间：{{(detail.disploy_at && detail.disploy_at.substring(0, 10)|| detail.date && detail.date.substring(0, 10))}} | 来源：明亚官网</div>
    </div>
    <div class="content" v-html="detail.detail || detail.content">

    </div>
    <div v-if="(type == 'life' || type == 'club') && (detail.next || detail.pre)" class="page">
      <div class="list">
        <div @click="getOtherDetail(detail.pre)" v-if="detail.pre" class="list-item">上一条：{{detail.pre.name}}</div>
        <div @click="getOtherDetail(detail.next)" v-if="detail.next" class="list-item">下一条：{{detail.next.name}}</div>
      </div>
      <div @click="backList" class="btn-back">返回列表</div>
    </div>
  </div>
</template>

<script>
import { getFamousDetail } from '@/api/eliteStyle'
export default {
  data() {
    return {
      id: '',
      detail: {},
      type: ''
    }
  },
  created() {
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    // if (this.type == 'life' || this.type == 'club') {
    //   this.getNewsOrDuty()
    // } else {
    //   this.getHonor()
    // }
    this.getDetail()
    document.body.scrollTop = document.documentElement.scrollTop = 0
  },
  filters: {
    dateFormat(val) {
      let d = new Date(val)
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      month = month < 0 ? '0' + month : month
      let day = d.getDate()
      d = day < 0 ? '0' + day : day
      return day + '/' + month + ' .' + year
    }
  },
  watch:{
    $route(to){
      if (to.name != 'eliteDetail') {
        this.$emit('loading', false)
        return false
      } else {
        this.id = this.$route.query.id
        this.type = this.$route.query.type
        this.getDetail()
        // document.body.scrollTop=document.documentElement.scrollTop = 0
      }
    }
  },
  methods: {
    // 返回上级列表
    backList() {
      if (this.type == 'life') {
        this.$router.push({ path: '/eliteStyle/life' })
      } else if (this.type == 'club') {
        this.$router.push({ path: '/eliteStyle/hof/club' })
      }
      // if (this.type == 'news') {
      //   console.log()
      //   this.$router.push({ path: '/aboutUs/news' })
      // }
    },
    async getDetail() {
      const res = await getFamousDetail({ id: this.id })
      res.detail = this.escape2Html(res.detail)
      this.detail = res
      this.$emit('loading', false)
      console.log(222)
    },
    getOtherDetail(data) {
      this.id = data.id
      let path = this.$router.history.current.path
      this.$router.push({ path, query: { type: this.type, id: data.id } })
      this.$emit('loading', true)
      console.log(11111)
      document.body.scrollTop = document.documentElement.scrollTop = 0
      this.getDetail()
    },
    // 获取荣誉详情
    // async getHonor () {
    //   const res = await getEventDetail({ id: this.id })
    //   res.content = this.escape2Html(res.content)
    //   this.detail = res
    // },
    // // 获取新闻或社会责任详情
    // async getNewsOrDuty () {
    //   const res = await getNewsAndDutyDetail({ id: this.id })
    //   res.detail = this.escape2Html(res.detail)
    //   this.detail = res
    //   this.$emit('loading', false)
    //   console.log(res)
    // },
    html2Escape(str) {
      return str.replace(/[<>&"]/g, function (c) {
        return { '<': '&lt;', '>': '&gt;', '&': '&amp;', '"': '&quot;' }[c]
      })
    },
    escape2Html(str) {
      var arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
        return arrEntities[t]
      })
    }
  }
}
</script>
<style lang="scss">
.elite-detail {
  .content {
    p, section {
      margin-top: .3rem!important;
    }
    p, section, span {
      margin-bottom: 0 !important;
      // text-indent: 2em;
      font-size: 16px!important;
      font-family: "Microsoft YaHei", Arial, sans-serif!important;
      text-align: justify;
      line-height: 2em!important;
    }
    img {
      margin: 0 auto !important;
      max-width: 100% !important;
      display: block;
    }
  }
}
</style>
<style lang="scss" scoped>
.detail {
  margin-left: 0.6rem;
  .title {
    // height: 1.65rem;
    padding-bottom: 0.4rem;
    padding-top: 0.2rem;
    border-bottom: 1px solid #dcdcdc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .name {
      font-size: 0.36rem;
      font-weight: bold;
    }
    .info {
      font-size: 0.16rem;
      color: #999999;
      margin-top: 0.2rem;
    }
  }
  .content {
    // border-bottom: 1px solid #dcdcdc;
    // padding-top: 0.4rem;
  }
  .page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 0 0;
    border-top: 1px solid #dcdcdc;
     margin-top: 0.6rem;
    .list {
      padding-right: 0.3rem;
      flex: 1;
      .list-item {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        font-size: 0.16rem;
        color: #666666;
        cursor: pointer;
        line-height: 0.3rem;
        &:hover {
          color: #ee5400;
        }
      }
    }
    .btn-back {
      width: 1.4rem;
      height: 0.44rem;
      background: #ffffff;
      border: 1px solid #d0d0d0;
      border-radius: 0.22rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.16rem;
      color: #666666;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        background-color: #ee5400;
        border-color: #ee5400;
        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-width: $moble_width) {
  .detail {
    margin-left: 0;
  }
}
</style>